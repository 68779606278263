@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
