@font-face {
  font-family: 'Lora';
  src: url('Lora-VariableFont_wght.ttf') format('truetype');
  font-weight: 400 700;
}

@font-face {
  font-family: 'Lora';
  src: url('Lora-Italic-VariableFont_wght.ttf') format('truetype');
  font-style: italic;
  font-weight: 400 700;
}
